import React, {useState} from 'react';
import classes from "./link.module.css";
import {CSSTransition} from "react-transition-group";
import {texts} from "../../../../../translate";
import Button from "../../button/button";
import {MEDIA_URL} from "../../../../../config";
import {subscribeChannel} from "../../../../../utils/api";
import Notificate from "../../notificate/notificate";

const Link = ({item, lang, setBalance, balance, setTimer}) => {
    const [open, setOpen] = useState(false)
    const [openFlag, setOpenFlag] = useState(false)
    const [tempFlag, setTempFlag] = useState(false)
    const [notificate, setNotificate] = useState(0)

    async function button(){
        if(openFlag){
            const res = await subscribeChannel(item.id)
            if(res.status === 'success'){
                setOpen(false)
                setTempFlag(true)
                setBalance(balance-5)
                setTimer(Date.now())
            }else{
                setNotificate(Date.now())
            }
        }else{
            setNotificate(Date.now())
        }
    }

    return (
        <div className={classes._}>
            <div className={classes.block} onClick={()=>{if(!item.is_subscribed)setOpen(true)}}>
                {item.type==='YouTube' &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                        <path d="M16.0834 25.6248L24.9496 20.4998L16.0834 15.3748V25.6248ZM35.8317 12.2486C36.0538 13.0515 36.2075 14.1278 36.31 15.4944C36.4296 16.8611 36.4809 18.0398 36.4809 19.0648L36.5834 20.4998C36.5834 24.2411 36.31 26.9915 35.8317 28.7511C35.4046 30.2886 34.4138 31.2794 32.8763 31.7065C32.0734 31.9286 30.6042 32.0823 28.3492 32.1848C26.1284 32.3044 24.0954 32.3557 22.2163 32.3557L19.5 32.4582C12.3421 32.4582 7.88335 32.1848 6.12377 31.7065C4.58627 31.2794 3.59544 30.2886 3.16835 28.7511C2.94627 27.9482 2.79252 26.8719 2.69002 25.5053C2.57044 24.1386 2.51919 22.9598 2.51919 21.9348L2.41669 20.4998C2.41669 16.7586 2.69002 14.0082 3.16835 12.2486C3.59544 10.7111 4.58627 9.72025 6.12377 9.29317C6.92669 9.07109 8.39585 8.91734 10.6509 8.81484C12.8717 8.69525 14.9046 8.644 16.7838 8.644L19.5 8.5415C26.6579 8.5415 31.1167 8.81484 32.8763 9.29317C34.4138 9.72025 35.4046 10.7111 35.8317 12.2486Z" fill="white"/>
                    </svg>}
                {item.type==='X' &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M0.058 1L9.325 13.39L0 23.462H2.099L10.262 14.642L16.858 23.462H24L14.212 10.375L22.892 1H20.792L13.275 9.122L7.2 1H0.058ZM3.145 2.546H6.425L20.913 21.916H17.633L3.145 2.546Z" fill="white"/>
                    </svg>}
                {item.type==='TikTok' &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.6333 3.96095C20.1684 3.9375 21.6918 3.94921 23.2151 3.9375C23.3089 5.73047 23.9534 7.55856 25.2658 8.82415C26.5783 10.1249 28.4298 10.7226 30.2344 10.9218V15.6444C28.5469 15.5858 26.8478 15.2342 25.3127 14.5077C24.6447 14.203 24.0237 13.8163 23.4143 13.4178C23.4026 16.8397 23.4261 20.2615 23.3909 23.6716C23.2972 25.3122 22.7581 26.9411 21.809 28.2888C20.2739 30.5388 17.6138 32.0035 14.8835 32.0504C13.2078 32.1442 11.5321 31.6871 10.1024 30.8434C7.73536 29.4489 6.07134 26.8942 5.82528 24.1521C5.80183 23.5661 5.79013 22.9802 5.81354 22.406C6.02448 20.1795 7.12599 18.0467 8.83687 16.5936C10.7821 14.9061 13.5007 14.0975 16.0436 14.578C16.067 16.3124 15.9967 18.0467 15.9967 19.781C14.8366 19.406 13.4773 19.5115 12.4578 20.2146C11.7195 20.6951 11.1571 21.4334 10.8641 22.2654C10.618 22.8631 10.6883 23.5193 10.7 24.1521C10.9813 26.0739 12.8328 27.6911 14.8014 27.5153C16.1139 27.5036 17.3677 26.7419 18.0474 25.6286C18.27 25.2419 18.5161 24.8435 18.5278 24.3864C18.645 22.2888 18.5981 20.2029 18.6099 18.1053C18.6216 13.3827 18.5981 8.67182 18.6333 3.96095Z" fill="white"/>
                    </svg>}
                {item.type==='Instagram' &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
                        <path d="M11.05 3.04541H22.95C27.4833 3.04541 31.1667 6.72874 31.1667 11.2621V23.1621C31.1667 25.3413 30.301 27.4312 28.7601 28.9721C27.2191 30.5131 25.1292 31.3787 22.95 31.3787H11.05C6.51668 31.3787 2.83334 27.6954 2.83334 23.1621V11.2621C2.83334 9.08288 3.69903 6.99294 5.23995 5.45202C6.78087 3.91109 8.87082 3.04541 11.05 3.04541ZM10.7667 5.87874C9.41407 5.87874 8.11687 6.41606 7.16043 7.3725C6.204 8.32893 5.66668 9.62614 5.66668 10.9787V23.4454C5.66668 26.2646 7.94751 28.5454 10.7667 28.5454H23.2333C24.5859 28.5454 25.8832 28.0081 26.8396 27.0517C27.796 26.0952 28.3333 24.798 28.3333 23.4454V10.9787C28.3333 8.15958 26.0525 5.87874 23.2333 5.87874H10.7667ZM24.4375 8.00374C24.9072 8.00374 25.3576 8.19031 25.6897 8.52241C26.0218 8.8545 26.2083 9.30492 26.2083 9.77458C26.2083 10.2442 26.0218 10.6946 25.6897 11.0267C25.3576 11.3588 24.9072 11.5454 24.4375 11.5454C23.9679 11.5454 23.5174 11.3588 23.1853 11.0267C22.8532 10.6946 22.6667 10.2442 22.6667 9.77458C22.6667 9.30492 22.8532 8.8545 23.1853 8.52241C23.5174 8.19031 23.9679 8.00374 24.4375 8.00374ZM17 10.1287C18.8786 10.1287 20.6803 10.875 22.0087 12.2034C23.3371 13.5318 24.0833 15.3335 24.0833 17.2121C24.0833 19.0907 23.3371 20.8924 22.0087 22.2208C20.6803 23.5491 18.8786 24.2954 17 24.2954C15.1214 24.2954 13.3197 23.5491 11.9913 22.2208C10.663 20.8924 9.91668 19.0907 9.91668 17.2121C9.91668 15.3335 10.663 13.5318 11.9913 12.2034C13.3197 10.875 15.1214 10.1287 17 10.1287ZM17 12.9621C15.8728 12.9621 14.7918 13.4098 13.9948 14.2069C13.1978 15.0039 12.75 16.0849 12.75 17.2121C12.75 18.3392 13.1978 19.4203 13.9948 20.2173C14.7918 21.0143 15.8728 21.4621 17 21.4621C18.1272 21.4621 19.2082 21.0143 20.0052 20.2173C20.8022 19.4203 21.25 18.3392 21.25 17.2121C21.25 16.0849 20.8022 15.0039 20.0052 14.2069C19.2082 13.4098 18.1272 12.9621 17 12.9621Z" fill="white"/>
                    </svg>}
                {item.type==='Telegram' &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                        <g clipPath="url(#clip0_380_2199)">
                            <rect width="35" height="35" fill="#B18B5B"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M28.8414 6.46037C29.2018 6.30869 29.5963 6.25637 29.9837 6.30887C30.3712 6.36137 30.7375 6.51676 31.0445 6.75886C31.3515 7.00097 31.5881 7.32095 31.7295 7.68549C31.8709 8.05002 31.912 8.4458 31.8485 8.83162L28.541 28.8939C28.2202 30.8291 26.0968 31.9389 24.3221 30.9749C22.8375 30.1685 20.6325 28.926 18.6491 27.6295C17.6575 26.9806 14.6198 24.9024 14.9931 23.4237C15.3139 22.1593 20.4181 17.4081 23.3348 14.5833C24.4796 13.4735 23.9575 12.8333 22.6056 13.8541C19.2485 16.3887 13.8585 20.2431 12.0764 21.3281C10.5043 22.2847 9.68476 22.4481 8.70476 22.2847C6.91685 21.9872 5.25872 21.5264 3.90539 20.9649C2.07664 20.2066 2.1656 17.6924 3.90393 16.9604L28.8414 6.46037Z" fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_380_2199">
                                <rect width="35" height="35" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>}
                {(item.is_subscribed || tempFlag) && <svg className={classes.ok} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="10" fill="#4AC64D"/>
                    <path d="M15.2563 7.33972L9.16658 13.4295L6.1217 10.3846" stroke="white" strokeWidth="1.92308" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>}
            </div>
            <div className={classes.text}>{item.type}</div>
            <CSSTransition in={open}
                           timeout={500}
                           classNames="openBonusFon"
                           unmountOnExit
            >
                <div className={classes.fon} onClick={()=>setOpen(false)}></div>
            </CSSTransition>
            <CSSTransition in={open}
                           timeout={500}
                           classNames="openBonusMenu"
                           unmountOnExit
            >
                <div className={classes.menu}>
                    <div className={classes.closeBlock}>
                        <svg className={classes.close} onClick={()=>setOpen(false)} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M35 20C35 21.9698 34.612 23.9204 33.8582 25.7403C33.1044 27.5601 31.9995 29.2137 30.6066 30.6066C29.2137 31.9995 27.5601 33.1044 25.7403 33.8582C23.9204 34.612 21.9698 35 20 35C18.0302 35 16.0796 34.612 14.2597 33.8582C12.4399 33.1044 10.7863 31.9995 9.3934 30.6066C8.00052 29.2137 6.89563 27.5601 6.14181 25.7403C5.38799 23.9204 5 21.9698 5 20C5 16.0218 6.58035 12.2064 9.3934 9.3934C12.2064 6.58035 16.0218 5 20 5C23.9782 5 27.7936 6.58035 30.6066 9.3934C33.4196 12.2064 35 16.0218 35 20ZM12.155 27.845C11.8425 27.5325 11.667 27.1086 11.667 26.6667C11.667 26.2247 11.8425 25.8009 12.155 25.4883L17.6433 20L12.155 14.5117C11.8514 14.1973 11.6834 13.7763 11.6872 13.3393C11.691 12.9023 11.8663 12.4843 12.1753 12.1753C12.4843 11.8663 12.9023 11.691 13.3393 11.6872C13.7763 11.6834 14.1973 11.8514 14.5117 12.155L20 17.6433L25.4883 12.155C25.6421 11.9958 25.826 11.8688 26.0293 11.7815C26.2327 11.6941 26.4514 11.6482 26.6727 11.6462C26.894 11.6443 27.1134 11.6865 27.3183 11.7703C27.5231 11.8541 27.7092 11.9779 27.8657 12.1343C28.0221 12.2908 28.1459 12.4769 28.2297 12.6817C28.3135 12.8866 28.3557 13.106 28.3538 13.3273C28.3518 13.5486 28.3058 13.7673 28.2185 13.9707C28.1312 14.174 28.0042 14.3579 27.845 14.5117L22.3567 20L27.845 25.4883C28.1486 25.8027 28.3166 26.2237 28.3128 26.6607C28.309 27.0977 28.1337 27.5157 27.8247 27.8247C27.5157 28.1337 27.0977 28.309 26.6607 28.3128C26.2237 28.3166 25.8027 28.1486 25.4883 27.845L20 22.3567L14.5117 27.845C14.1991 28.1575 13.7753 28.333 13.3333 28.333C12.8914 28.333 12.4675 28.1575 12.155 27.845Z" fill="#4C4F55"/>
                        </svg>
                    </div>
                    <img className={classes.img2} src={MEDIA_URL+(lang==='ru'?item.image:item.image_en)} alt={''}/>
                    <div className={classes.menuText1}>{lang==='ru'?item.name:item.name_en}</div>
                    <div className={classes.menuText2}>{lang==='ru'?item.desc:item.desc_en}</div>
                    <div className={classes.menuButtons}>
                        <Button active={true} click={()=>{
                            setOpenFlag(true);
                            window.open(lang==='ru'?item.link:item.link_en);
                        }}>
                            {texts[lang].profit.text4}
                        </Button>
                    </div>
                    <div className={classes.menuButtons2}>
                        <Button active={true} click={button}>
                            {texts[lang].profit.text5}
                        </Button>
                    </div>
                </div>
            </CSSTransition>
            <Notificate text={texts[lang].notificate.text5} open={notificate}/>
        </div>
    );
};

export default Link;