import React, {useEffect, useState} from 'react';
import coin from '../../../../images/coin.png'
import classes from "./roadmap.module.css";
import {texts} from "../../../../translate";
import {getLinks, getReferrals} from "../../../../utils/api";

const Roadmap = ({lang, setActive, data}) => {
    const [step1, setStep1] = useState(false)
    const [step2, setStep2] = useState(false)
    const [step3, setStep3] = useState(false)

    useEffect(()=>{
        if(data?.user.is_active){
            setStep1(true)
        }
    },[data])

    useEffect(()=>{
        const getReferralsF = async ()=>{
            const res = await getReferrals()
            if(res.error){
                console.log(res)
            }
            else{
                setStep2(res.refferal_levels.filter(item=>item.level===1)[0].num_refferals === 10)
            }
        }
        getReferralsF()

        const getLinksF = async ()=>{
            const res = await getLinks()
            if(res.error){
                console.log(res)
            }
            else{
                let temp = 0;
                res.forEach((item)=>{
                    temp += getCount(item.channels)
                })
                setStep3(temp === 20)
            }
        }
        getLinksF()
    },[])


    function getCount(mas){
        return mas.filter(item=>item.is_subscribed).length
    }


    return (
        <div className={classes._}>
            <img className={classes.coin} alt={''} src={coin}/>
            <div className={classes.text1}>{texts[lang].roadmap.text1}</div>
            <div className={classes.blocks}>
                <div className={classes.block} onClick={()=>{if(!step1)setActive(1)}}>
                    <div className={classes.number}>1</div>
                    <div>
                        <div className={classes.text2}>{texts[lang].roadmap.text2}</div>
                        <div className={classes.text2}>{texts[lang].roadmap.text3}</div>
                        <div className={classes.text3+' '+(step1 && classes.text3_1)}>{texts[lang].roadmap.text4}</div>
                    </div>
                </div>
                <div className={classes.block} onClick={()=>{if(!step2)setActive(2)}}>
                    <div className={classes.number}>2</div>
                    <div>
                        <div className={classes.text2}>{texts[lang].roadmap.text5}</div>
                        <div className={classes.text2}>{texts[lang].roadmap.text6}</div>
                        <div className={classes.text3+' '+(step2 && classes.text3_1)}>{texts[lang].roadmap.text7}</div>
                    </div>
                </div>
                <div className={classes.block} onClick={()=>{if(!step3)setActive(3)}}>
                    <div className={classes.number}>3</div>
                    <div>
                        <div className={classes.text2}>{texts[lang].roadmap.text8}</div>
                        <div className={classes.text2}>{texts[lang].roadmap.text9}</div>
                        <div className={classes.text3+' '+(step3 && classes.text3_1)}>{texts[lang].roadmap.text10}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roadmap;