import React, {useEffect, useState} from 'react';
import classes from "./profit.module.css";
import {texts} from "../../../../translate";
import InfoRefs from "../infoRefs/infoRefs";
import Link from "./link/link";
import linkImg from '../../../../images/link.png'
import {getLinks, getReferrals} from "../../../../utils/api";

const Profit = ({lang, setBalance, balance}) => {
    const [openInfo, setOpenInfo] = useState(false)
    const [countLink, setCountLink] = useState(0)
    const [openLink, setOpenLink] = useState(0)
    const [timer, setTimer] = useState(0)
    const [data, setData] = useState()
    const [links, setLinks] = useState([
            {
                channels:[
                    {id:0,link:'https://proself.group',type:'YouTube',flag:true,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                    {id:1,link:'https://proself.group',type:'X',flag:true,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                    {id:2,link:'https://proself.group',type:'TikTok',flag:true,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                    {id:3,link:'https://proself.group',type:'Instagram',flag:true,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                    {id:4,link:'https://proself.group',type:'Telegram',flag:true,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                ]
            },
            {
                channels:[
                    {id:5,link:'https://proself.group',type:'YouTube',flag:true,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                    {id:6,link:'https://proself.group',type:'X',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                    {id:7,link:'https://proself.group',type:'TikTok',flag:true,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                    {id:8,link:'https://proself.group',type:'Instagram',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                    {id:9,link:'https://proself.group',type:'Telegram',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                ]
            },
            {
                channels:[
            {id:10,link:'https://proself.group',type:'YouTube',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
            {id:11,link:'https://proself.group',type:'X',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
            {id:12,link:'https://proself.group',type:'TikTok',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
            {id:13,link:'https://proself.group',type:'Instagram',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
            {id:14,link:'https://proself.group',type:'Telegram',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                ]
            },
            {
                channels:[
            {id:15,link:'https://proself.group',type:'YouTube',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
            {id:16,link:'https://proself.group',type:'X',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
            {id:17,link:'https://proself.group',type:'TikTok',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
            {id:18,link:'https://proself.group',type:'Instagram',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
            {id:19,link:'https://proself.group',type:'Telegram',flag:false,img:linkImg,name:'Крипта ТОП',desc:'все о крипте: обзоры, стратегии, новости. Стань боссом крипты с нами!'},
                ]
            }
    ])

    const getLinksF = async ()=>{
        const res = await getLinks()
        console.log(res)
        if(res.error){
            console.log(res)
        }
        else{
            setLinks(res.sort(function (a, b) {
                if (a.level > b.level) {
                    return 1;
                }
                if (a.level < b.level) {
                    return -1;
                }
                return 0;
            }))
        }
    }

    useEffect(()=>{
        getLinksF()
        const getReferralsF = async ()=>{
            const res = await getReferrals()
            if(res.error){
                console.log(res)
            }
            else{
                setData(res)
            }
        }
        getReferralsF()
    },[])


    function getCount(mas){
        return mas.filter(item=>item.is_subscribed).length
    }

    useEffect(()=>{
        let temp = 0;
        let flag = true
        links.forEach((item,number)=>{
            temp += getCount(item.channels)
            if(timer===0 && getCount(item.channels) < 5 && flag){
                setOpenLink(number+1)
                flag = false
            }
        })
        setCountLink(temp)
    },[links])

    useEffect(()=>{
        getLinksF()
    },[timer])

    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div className={classes.block1}>
                    <div className={classes.text1Block}>
                        <div className={classes.text1}>{texts[lang].profit.text1}</div>
                        <div className={classes.text1_1}><span className={classes.text1_2}>{countLink}</span> / 20</div>
                    </div>
                    <div className={classes.greenCoin}>+{20 - countLink}coin</div>
                    <svg className={classes.info} onClick={()=>setOpenInfo(!openInfo)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.5" d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C12.9993 7.71733 12.9033 7.48 12.712 7.288C12.5207 7.096 12.2833 7 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C10.9993 8.28267 11.0953 8.52033 11.288 8.713C11.4807 8.90567 11.718 9.00133 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88334 20.6867 5.825 19.9743 4.925 19.075C4.025 18.1757 3.31267 17.1173 2.788 15.9C2.26333 14.6827 2.00067 13.3827 2 12C1.99933 10.6173 2.262 9.31733 2.788 8.1C3.314 6.88267 4.02633 5.82433 4.925 4.925C5.82367 4.02567 6.882 3.31333 8.1 2.788C9.318 2.26267 10.618 2 12 2C13.382 2 14.682 2.26267 15.9 2.788C17.118 3.31333 18.1763 4.02567 19.075 4.925C19.9737 5.82433 20.6863 6.88267 21.213 8.1C21.7397 9.31733 22.002 10.6173 22 12C21.998 13.3827 21.7353 14.6827 21.212 15.9C20.6887 17.1173 19.9763 18.1757 19.075 19.075C18.1737 19.9743 17.1153 20.687 15.9 21.213C14.6847 21.739 13.3847 22.0013 12 22Z" fill="white"/>
                    </svg>
                </div>
            </div>
            {!openInfo?
                <div className={classes.blocks}>
                    <div className={classes.block2}>
                        <div className={classes.block2_1}>
                            <div className={classes.star}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="29" viewBox="0 0 31 29" fill="none">
                                    <path d="M15.5715 0L20.9716 8.13893L30.3809 10.7597L24.3091 18.4105L24.7243 28.1692L15.5715 24.7587L6.41882 28.1692L6.83398 18.4105L0.762125 10.7597L10.1714 8.13893L15.5715 0Z" fill="#B18B5B"/>
                                </svg>
                                <div className={classes.starsNumber} style={{left:'12px'}}>1</div>
                            </div>
                            <div>{texts[lang].profit.text2}</div>
                            <div className={classes.text2_1}>10 {texts[lang].profit.text3}</div>
                        </div>
                    </div>
                    {links.map((item, number)=>
                        <div key={number}>
                            <div className={classes.block2} onClick={()=>setOpenLink(openLink===number+1?0:number+1)}>
                                <div className={classes.block2_1}>
                                    <div className={classes.star}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="29" viewBox="0 0 31 29" fill="none">
                                            <path d="M15.5715 0L20.9716 8.13893L30.3809 10.7597L24.3091 18.4105L24.7243 28.1692L15.5715 24.7587L6.41882 28.1692L6.83398 18.4105L0.762125 10.7597L10.1714 8.13893L15.5715 0Z" fill="#B18B5B"/>
                                        </svg>
                                        <div className={classes.starsNumber} style={{left:'12px'}}>{number+2}</div>
                                    </div>
                                    <div>{texts[lang].profit.text2}</div>
                                    <div className={getCount(item.channels) > 0 ? classes.text2_1 : ''}>{getCount(item.channels)+5} {texts[lang].profit.text3}</div>
                                </div>
                                {getCount(item.channels) === 5?
                                    <svg style={{cursor:'pointer'}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10" r="10" fill="#4AC64D"/>
                                        <path d="M15.2563 7.33972L9.16658 13.4295L6.1217 10.3846" stroke="white" strokeWidth="1.92308" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>:
                                    <svg style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <circle cx="10" cy="10.7878" r="10" fill="#4AC64D"/>
                                        <rect x="4.5" y="9.78784" width="11" height="2" rx="1" fill="white"/>
                                        <rect style={{transition:'all .3s ease'}} x="9" y="16.2878" width="11" height="2" rx="1" transform="rotate(-90 9 16.2878)" fill="white" opacity={openLink===number+1?0:1}/>
                                    </svg>
                                }
                            </div>
                            <div className={classes.linksBlock} style={openLink === number + 1?{height:'86px'}:{transform:'scaleY(0)', height:0, margin:0, opacity:0}}>
                                {item.channels.map(item2 =>
                                    <Link lang={lang} key={item2.id} item={item2} setBalance={setBalance} balance={balance} setTimer={setTimer}/>
                                )}
                            </div>
                        </div>
                    )}
                </div> :
                <InfoRefs lang={lang} data={data}/>
            }
        </div>
    );
};

export default Profit;