import React from 'react';
import classes from "./politics.module.css";
import {texts} from "../../../../translate";

const Politics = ({lang}) => {
    return (
        <div className={classes._}>
            <div className={classes.text}>{texts[lang].politics.text1}</div>
            <div className={classes.text}>{texts[lang].politics.text2}</div>
        </div>
    );
};

export default Politics;