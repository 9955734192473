import React from 'react';
import classes from "./button.module.css";

const Button = ({children, click, active}) => {
    return (
        <div className={active?classes._:classes.__} onClick={click}>
            {children}
        </div>
    );
};

export default Button;