import React, {useEffect, useState} from 'react';
import classes from "./header.module.css";
import Settings from "../settings/settings";
import {CSSTransition} from "react-transition-group";
import {useTelegram} from "../../../../hooks/useTelegram";
import {MEDIA_URL} from "../../../../config";
import eng from '../../../../images/eng.png'
import rus from '../../../../images/rus.png'

const Header = ({openSettings, setOpenSettings, lang, setLang, data, cryptoAddress, setCryptoAddress, setAutomateWithdrawal, automateWithdrawal}) => {
    const {user} = useTelegram()

    const [name, setName] = useState('')
    const [countRef, setCountRef] = useState(0)
    const [avatar, setAvatar] = useState(null)
    const [openLang, setOpenLang] = useState(false)

    useEffect(()=>{
        if(user && data){
            setName((user?.first_name?user?.first_name:'')+' '+(user?.last_name?user?.last_name:''))
            setAvatar(MEDIA_URL+data?.user?.avatar)
            setCountRef(data?.total_refferals)
        }
    },[user, data])

    return (
        <div className={classes._}>
            <div className={classes.blockUser}>
                <div className={classes.avatar}>
                    {avatar ? <img alt={''} src={avatar} className={classes.avatarImage}/>:
                        <div className={classes.avatarImage2}>{name.slice(0,1)}</div>
                    }
                </div>
                <div className={classes.username}>{name}</div>
                <div className={classes.friends}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M13.3334 14.1669V15.8335H1.66669V14.1669C1.66669 14.1669 1.66669 10.8335 7.50002 10.8335C13.3334 10.8335 13.3334 14.1669 13.3334 14.1669ZM10.4167 6.25019C10.4167 5.67333 10.2456 5.10942 9.92514 4.62978C9.60465 4.15014 9.14913 3.7763 8.61618 3.55554C8.08323 3.33479 7.49678 3.27703 6.93101 3.38957C6.36523 3.50211 5.84553 3.7799 5.43763 4.1878C5.02972 4.5957 4.75194 5.1154 4.6394 5.68118C4.52686 6.24696 4.58462 6.8334 4.80537 7.36635C5.02613 7.8993 5.39996 8.35483 5.87961 8.67531C6.35925 8.9958 6.92316 9.16686 7.50002 9.16686C8.27357 9.16686 9.01543 8.85957 9.56241 8.31259C10.1094 7.76561 10.4167 7.02374 10.4167 6.25019ZM13.2834 10.8335C13.7956 11.23 14.2148 11.7339 14.5114 12.3098C14.808 12.8857 14.9748 13.5196 15 14.1669V15.8335H18.3334V14.1669C18.3334 14.1669 18.3334 11.1419 13.2834 10.8335ZM12.5 3.33353C11.9265 3.33086 11.3657 3.5023 10.8917 3.82519C11.3979 4.53247 11.6701 5.38043 11.6701 6.25019C11.6701 7.11995 11.3979 7.96791 10.8917 8.67519C11.3657 8.99808 11.9265 9.16953 12.5 9.16686C13.2736 9.16686 14.0154 8.85957 14.5624 8.31259C15.1094 7.76561 15.4167 7.02374 15.4167 6.25019C15.4167 5.47665 15.1094 4.73478 14.5624 4.1878C14.0154 3.64082 13.2736 3.33353 12.5 3.33353Z" fill="#4DE076"/>
                    </svg>
                    {countRef}
                </div>
            </div>
            <div className={classes.settings}>
                {openLang && <div className={classes.fon2} onClick={()=>setOpenLang(false)}></div>}
                <div className={classes.langBlock} onClick={()=>setOpenLang(true)}>
                    <img className={classes.lang+' '+(lang==='ru'&&openLang&&classes.langOpen)+' '+(lang!=='ru'&&classes.selectLang)}
                         onClick={(e)=>{e.stopPropagation();setLang('eng');setOpenLang(!openLang)}}
                         src={eng} alt={''}/>
                    <img className={classes.lang+' '+(lang!=='ru'&&openLang&&classes.langOpen)+' '+(lang==='ru'&&classes.selectLang)}
                         onClick={(e)=>{e.stopPropagation();setLang('ru');setOpenLang(!openLang)}}
                         src={rus} alt={''}/>
                    <svg className={classes.strLang+' '+(openLang&&classes.strLangOpen)}
                         onClick={(e)=>{e.stopPropagation(); setOpenLang(!openLang)}}
                         xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                        <path d="M6.99985 7.50003C6.73724 7.50049 6.47713 7.44904 6.23446 7.34865C5.9918 7.24825 5.77137 7.10089 5.58585 6.91503L0.292847 1.62103L1.70685 0.207031L6.99985 5.50003L12.2928 0.207031L13.7068 1.62103L8.41385 6.91403C8.2284 7.10007 8.00801 7.24762 7.76535 7.34819C7.52268 7.44875 7.26253 7.50036 6.99985 7.50003Z" fill="white"/>
                    </svg>
                </div>
                <svg className={classes.buttonSetting} xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M19.5936 3.4375C19.5936 3.34633 19.5574 3.2589 19.4929 3.19443C19.4284 3.12997 19.341 3.09375 19.2498 3.09375H9.62482C8.62197 3.09375 7.66019 3.49213 6.95107 4.20125C6.24195 4.91037 5.84357 5.87215 5.84357 6.875V26.125C5.84357 27.1278 6.24195 28.0896 6.95107 28.7987C7.66019 29.5079 8.62197 29.9062 9.62482 29.9062H23.3748C24.3777 29.9062 25.3394 29.5079 26.0486 28.7987C26.7577 28.0896 27.1561 27.1278 27.1561 26.125V12.5771C27.1561 12.486 27.1199 12.3985 27.0554 12.3341C26.9909 12.2696 26.9035 12.2334 26.8123 12.2334H20.6248C20.3513 12.2334 20.089 12.1247 19.8956 11.9313C19.7022 11.7379 19.5936 11.4756 19.5936 11.2021V3.4375ZM20.6248 16.8438C20.8983 16.8438 21.1606 16.9524 21.354 17.1458C21.5474 17.3392 21.6561 17.6015 21.6561 17.875C21.6561 18.1485 21.5474 18.4108 21.354 18.6042C21.1606 18.7976 20.8983 18.9062 20.6248 18.9062H12.3748C12.1013 18.9062 11.839 18.7976 11.6456 18.6042C11.4522 18.4108 11.3436 18.1485 11.3436 17.875C11.3436 17.6015 11.4522 17.3392 11.6456 17.1458C11.839 16.9524 12.1013 16.8438 12.3748 16.8438H20.6248ZM20.6248 22.3438C20.8983 22.3438 21.1606 22.4524 21.354 22.6458C21.5474 22.8392 21.6561 23.1015 21.6561 23.375C21.6561 23.6485 21.5474 23.9108 21.354 24.1042C21.1606 24.2976 20.8983 24.4062 20.6248 24.4062H12.3748C12.1013 24.4062 11.839 24.2976 11.6456 24.1042C11.4522 23.9108 11.3436 23.6485 11.3436 23.375C11.3436 23.1015 11.4522 22.8392 11.6456 22.6458C11.839 22.4524 12.1013 22.3438 12.3748 22.3438H20.6248Z" fill="#B18B5B"/>
                    <path d="M21.6562 3.883C21.6562 3.63 21.9216 3.46913 22.1183 3.62725C22.2846 3.762 22.4345 3.91875 22.5624 4.0975L26.7053 9.86838C26.7988 10.0004 26.697 10.1709 26.5348 10.1709H22C21.9088 10.1709 21.8214 10.1347 21.7569 10.0702C21.6925 10.0057 21.6562 9.91829 21.6562 9.82713V3.883Z" fill="#B18B5B"/>
                </svg>
                <svg className={openSettings?classes.buttonSetting2:classes.buttonSetting} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" onClick={()=>setOpenSettings(true)}>
                    <path d="M16 20.6665C14.7623 20.6665 13.5753 20.1748 12.7002 19.2997C11.825 18.4245 11.3333 17.2375 11.3333 15.9998C11.3333 14.7622 11.825 13.5752 12.7002 12.7C13.5753 11.8248 14.7623 11.3332 16 11.3332C17.2377 11.3332 18.4247 11.8248 19.2998 12.7C20.175 13.5752 20.6667 14.7622 20.6667 15.9998C20.6667 17.2375 20.175 18.4245 19.2998 19.2997C18.4247 20.1748 17.2377 20.6665 16 20.6665ZM25.9067 17.2932C25.96 16.8665 26 16.4398 26 15.9998C26 15.5598 25.96 15.1198 25.9067 14.6665L28.72 12.4932C28.9733 12.2932 29.04 11.9332 28.88 11.6398L26.2133 7.02651C26.0533 6.73317 25.6933 6.61317 25.4 6.73317L22.08 8.06651C21.3867 7.54651 20.6667 7.09317 19.8267 6.75984L19.3333 3.22651C19.3062 3.06946 19.2244 2.92708 19.1024 2.82458C18.9804 2.72207 18.826 2.66608 18.6667 2.66651H13.3333C13 2.66651 12.72 2.90651 12.6667 3.22651L12.1733 6.75984C11.3333 7.09317 10.6133 7.54651 9.92 8.06651L6.6 6.73317C6.30667 6.61317 5.94667 6.73317 5.78667 7.02651L3.12 11.6398C2.94667 11.9332 3.02667 12.2932 3.28 12.4932L6.09333 14.6665C6.04 15.1198 6 15.5598 6 15.9998C6 16.4398 6.04 16.8665 6.09333 17.2932L3.28 19.5065C3.02667 19.7065 2.94667 20.0665 3.12 20.3598L5.78667 24.9732C5.94667 25.2665 6.30667 25.3732 6.6 25.2665L9.92 23.9198C10.6133 24.4532 11.3333 24.9065 12.1733 25.2398L12.6667 28.7732C12.72 29.0932 13 29.3332 13.3333 29.3332H18.6667C19 29.3332 19.28 29.0932 19.3333 28.7732L19.8267 25.2398C20.6667 24.8932 21.3867 24.4532 22.08 23.9198L25.4 25.2665C25.6933 25.3732 26.0533 25.2665 26.2133 24.9732L28.88 20.3598C29.04 20.0665 28.9733 19.7065 28.72 19.5065L25.9067 17.2932Z" fill="#B18B5B"/>
                </svg>
            </div>
            <CSSTransition in={openSettings}
                           timeout={500}
                           classNames="openBonusFon"
                           unmountOnExit
            >
                <div className={classes.fon}></div>
            </CSSTransition>
            <CSSTransition in={openSettings}
                           timeout={500}
                           classNames="openBonusMenu"
                           unmountOnExit
            >
                <Settings lang={lang}
                          name={name}
                          avatar={avatar}
                          setOpenSettings={setOpenSettings}
                          cryptoAddress={cryptoAddress}
                          setCryptoAddress={setCryptoAddress}
                          automateWithdrawal={automateWithdrawal}
                          setAutomateWithdrawal={setAutomateWithdrawal}/>
            </CSSTransition>
        </div>
    );
};

export default Header;