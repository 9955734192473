import React, {useEffect, useState} from 'react';
import classes from "./notificate.module.css";
import {CSSTransition} from "react-transition-group";

const Notificate = ({text, open}) => {
    const [flag, setFlag] = useState(false)

    useEffect(()=>{
        if(open !== 0){
            setFlag(true);
            setTimeout(() => setFlag(false), 2000);
        }
    },[open])

    return (
        <>
            <CSSTransition in={flag}
                           timeout={500}
                           classNames="openBonusFon"
                           unmountOnExit
            >
                <div className={classes.fon}></div>
            </CSSTransition>
            <CSSTransition in={flag}
                           timeout={500}
                           classNames="openBonusFon"
                           unmountOnExit
            >
                <div className={classes._}>{text}</div>
            </CSSTransition>
        </>
    );
};

export default Notificate;