import React, {useEffect, useState} from 'react';
import classes from "./startBlock.module.css";
import background1 from "../../../../images/background1.png";
import {texts} from "../../../../translate";
import {getSocialLinks} from "../../../../utils/api";

const StartBlock = ({setStartBlock, lang}) => {
    const [links, setLinks] = useState()

    useEffect(()=>{
        const getLinks = async ()=>{
            const res = await getSocialLinks()
            setLinks(res)
            console.log(res)
        }
        getLinks()
    },[])

    return (
        <div className={classes.startBlock}>
            <img alt={''} src={background1} className={classes.background1} onClick={()=>setStartBlock(false)}/>
            <div className={classes.startBlockText}>{texts[lang].start.text1}<br/> {texts[lang].start.text2}</div>
            <div className={classes.startBlockButtons}>
                <svg style={{cursor:'pointer'}} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>window.open(links[0].link)}>
                    <circle cx="30" cy="30" r="19.5" fill="#D9D9D9"/>
                    <path d="M34.875 30L27 25.5V34.5L34.875 30Z" fill="black"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30ZM41.625 20.0625C42.9375 20.4375 43.875 21.375 44.25 22.6875C45 25.125 45 30 45 30C45 30 45 34.875 44.4375 37.3125C44.0625 38.625 43.125 39.5625 41.8125 39.9375C39.375 40.5 30 40.5 30 40.5C30 40.5 20.4375 40.5 18.1875 39.9375C16.875 39.5625 15.9375 38.625 15.5625 37.3125C15 34.875 15 30 15 30C15 30 15 25.125 15.375 22.6875C15.75 21.375 16.6875 20.4375 18 20.0625C20.4375 19.5 29.8125 19.5 29.8125 19.5C29.8125 19.5 39.375 19.5 41.625 20.0625Z" fill="black"/>
                </svg>
                <div className={classes.icons} onClick={()=>window.open(links[1].link)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                        <circle cx="30" cy="30" r="30" fill="black"/>
                    </svg>
                    <svg className={classes.icon2X} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M0.058 1L9.325 13.39L0 23.462H2.099L10.262 14.642L16.858 23.462H24L14.212 10.375L22.892 1H20.792L13.275 9.122L7.2 1H0.058ZM3.145 2.546H6.425L20.913 21.916H17.633L3.145 2.546Z" fill="white"/>
                    </svg>
                </div>
                <div className={classes.icons} onClick={()=>window.open(links[2].link)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                        <circle cx="30" cy="30" r="30" fill="black"/>
                    </svg>
                    <svg className={classes.icon2T} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.6333 3.96095C20.1684 3.9375 21.6918 3.94921 23.2151 3.9375C23.3089 5.73047 23.9534 7.55856 25.2658 8.82415C26.5783 10.1249 28.4298 10.7226 30.2344 10.9218V15.6444C28.5469 15.5858 26.8478 15.2342 25.3127 14.5077C24.6447 14.203 24.0237 13.8163 23.4143 13.4178C23.4026 16.8397 23.4261 20.2615 23.3909 23.6716C23.2972 25.3122 22.7581 26.9411 21.809 28.2888C20.2739 30.5388 17.6138 32.0035 14.8835 32.0504C13.2078 32.1442 11.5321 31.6871 10.1024 30.8434C7.73536 29.4489 6.07134 26.8942 5.82528 24.1521C5.80183 23.5661 5.79013 22.9802 5.81354 22.406C6.02448 20.1795 7.12599 18.0467 8.83687 16.5936C10.7821 14.9061 13.5007 14.0975 16.0436 14.578C16.067 16.3124 15.9967 18.0467 15.9967 19.781C14.8366 19.406 13.4773 19.5115 12.4578 20.2146C11.7195 20.6951 11.1571 21.4334 10.8641 22.2654C10.618 22.8631 10.6883 23.5193 10.7 24.1521C10.9813 26.0739 12.8328 27.6911 14.8014 27.5153C16.1139 27.5036 17.3677 26.7419 18.0474 25.6286C18.27 25.2419 18.5161 24.8435 18.5278 24.3864C18.645 22.2888 18.5981 20.2029 18.6099 18.1053C18.6216 13.3827 18.5981 8.67182 18.6333 3.96095Z" fill="white"/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default StartBlock;