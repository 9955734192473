import React, {useState} from 'react';
import classes from "./infoRefs.module.css";
import {texts} from "../../../../translate";
import coin from "../../../../images/coin.png";

const InfoRefs = ({lang, data}) => {

    return (
        <div className={classes.blockInfo}>
            <div className={classes.blockInfoText1}>{texts[lang].referrals.text3}</div>
            {
                data?.refferal_levels.map(
                    (item, number) =>
                        <div className={classes.blockInfoBlock1} key={number}>
                            <div className={classes.star}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                                    <path d="M12.3139 0L16.5198 6.33902L23.8483 8.38018L19.1192 14.3391L19.4426 21.9396L12.3139 19.2834L5.18534 21.9396L5.50869 14.3391L0.779617 8.38018L8.10807 6.33902L12.3139 0Z" fill="#B18B5B"/>
                                </svg>
                                <div className={classes.starsNumber} style={{left:number+1===1?'10px':''}}>{number+1}</div>
                            </div>
                            <div className={classes.blockInfoBlock1Number}>{data?.refferal_levels?.filter(item=>item.level===number+1)[0].num_refferals}</div>
                            <div className={classes.blockInfoBlock1Text}>{texts[lang].referrals.text4} {data?.refferal_levels?.filter(item=>item.level===number+1)[0].num_coins} {texts[lang].referrals.text4_1}</div>
                        </div>
                )
            }
            <div className={classes.blockInfoBlock2}>
                <img src={coin} alt={''} className={classes.coin2}/>
                <div className={classes.blockInfoBlock1Text2}>{texts[lang].referrals.text5}</div>
            </div>
        </div>
    );
};

export default InfoRefs;