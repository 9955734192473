import React, {useState} from 'react';
import classes from "./settings.module.css";
import {texts} from "../../../../translate";
import {saveMe} from "../../../../utils/api";

const Settings = ({lang, name, avatar, setOpenSettings, cryptoAddress, setAutomateWithdrawal, automateWithdrawal, setCryptoAddress}) => {
    //const [address, setAddress] = useState('')
    //const [value, setValue] = useState(false)

    async function saveSetting(){
        await saveMe(cryptoAddress, automateWithdrawal)
        setOpenSettings(false)
    }

    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div className={classes.block1}>
                    {avatar ? <img alt={''} src={avatar} className={classes.avatar}/>:
                        <div className={classes.avatar2}>{name.slice(0,1)}</div>
                    }
                    <div className={classes.name}>{name}</div>
                </div>
                <input className={classes.input}
                       value={cryptoAddress}
                       onChange={e=>setCryptoAddress(e.target.value)}
                       placeholder={texts[lang].settings.address}
                />
                <div className={classes.block2}>
                    <div className={classes.text}>{texts[lang].settings.text}</div>
                    <label className={classes.workRightSwitch}>
                        <input
                            type={"checkbox"}
                            checked={ automateWithdrawal }
                            onChange={(e) => setAutomateWithdrawal(e.target.checked)}
                        />
                        <span className={classes.workRightSlider}></span>
                    </label>
                </div>
            </div>
            <div className={classes.save} onClick={saveSetting}>{texts[lang].settings.save}</div>
        </div>
    );
};

export default Settings;