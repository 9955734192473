export function useTelegram() {
  const webApp = window.Telegram.WebApp;
  const user = webApp.initDataUnsafe?.user;
  const langT = webApp.initDataUnsafe?.user?.language_code === 'ru' ? 'ru' : 'eng'
  //const lang = 'ru'
  return {
    webApp,
    user,
    langT
  };
}
