import React, {useCallback, useEffect, useState} from 'react';
import classes from "./main.module.css";
import StartBlock from "./startBlock/startBlock";
import Header from "./header/header";
import Bonuses from "./bonuses/bonuses";
import background2 from '../../../images/background2.png'
import Balance from "./balance/balance";
import Button from "./button/button";
import {texts} from "../../../translate";
import Menu from "./menu/menu";
import Politics from "./politics/politics";
import Referrals from "./referrals/referrals";
import Roadmap from "./roadmap/roadmap";
import Profit from "./profit/profit";
import {getMe, topUpBalance} from "../../../utils/api";
import {useTelegram} from "../../../hooks/useTelegram";
import {CSSTransition} from "react-transition-group";
import Notificate from "./notificate/notificate";
import Withdraw from "./withdraw/withdraw";

const Main = () => {
    const {webApp, langT} = useTelegram()
    const [startBlock, setStartBlock] = useState(true)
    const [openSettings, setOpenSettings] = useState(false)
    const [balance, setBalance] = useState(0)
    const [active, setActive] = useState(1)
    const [lang, setLang] = useState(langT)
    const [data, setData] = useState()
    const [cryptoAddress, setCryptoAddress] = useState('')
    const [automateWithdrawal, setAutomateWithdrawal] = useState(false)
    const [timer, setTimer] = useState(0)
    const [notification, setNotification] = useState(0)
    const [openWithdraw, setOpenWithdraw] = useState(false)

    const onBackClick = useCallback(() => {
        setTimer(Date.now())
    },[timer])

    useEffect(()=>{
        webApp.BackButton.hide()
        setOpenSettings(false)
        getMeF()
    },[timer])

    useEffect(()=>{
        if(openSettings){
            webApp.BackButton.show()
        }
    },[openSettings])

    useEffect(() => {
        webApp.ready()
        //webApp.enableClosingConfirmation()
        webApp.expand()
        webApp.BackButton.onClick(onBackClick)
        return () => {
            webApp.BackButton.offClick(onBackClick)
        };
    }, [webApp])

    const getMeF = async ()=>{
        const res = await getMe()
        console.log(res)
        if(!res.error){
            setData(res)
            setBalance(res?.user?.balance)
            setCryptoAddress(res?.user?.crypto_address)
            setAutomateWithdrawal(res?.user?.automate_withdrawal)
        }
    }

    useEffect(()=>{
        getMeF()
        setTimeout(()=>{setStartBlock(false)},5000)
    },[])

    useEffect(()=>{
        getMeF()
        if(active === 2 || active === 3){
            if(!data?.user.is_active){
                setActive(1)
                setNotification(Date.now());
            }
        }
    },[active])

    async function addBalance(){
        if(data.user.is_active){
            setOpenWithdraw(true)
        }else{
            const res = await topUpBalance()
            console.log(res)
            setBalance(100)
            getMeF()
        }
    }

    return (
        <>
            {startBlock && <StartBlock lang={lang} setStartBlock={setStartBlock}/>}
            <div style={{opacity:startBlock?0:1}} className={classes._}>
                <Header lang={lang}
                        setLang={setLang}
                        openSettings={openSettings}
                        setOpenSettings={setOpenSettings}
                        data={data}
                        cryptoAddress={cryptoAddress}
                        setCryptoAddress={setCryptoAddress}
                        automateWithdrawal={automateWithdrawal}
                        setAutomateWithdrawal={setAutomateWithdrawal}
                />
                {active === 1 &&
                    <>
                        <Bonuses lang={lang}/>
                        <img alt={''} src={background2} className={classes.background2}/>
                        <Balance lang={lang} value={balance}/>
                        <Button click={addBalance} active={true}>{balance===0?texts[lang].button.text1:texts[lang].button.text2}</Button>
                        <div style={{height:'78px'}}></div>
                    </>
                }
                {active === 2 &&
                    <>
                        <Bonuses lang={lang}/>
                        <img alt={''} src={background2} className={classes.background2}/>
                        <Balance lang={lang} value={balance}/>
                        <Referrals lang={lang}/>
                    </>
                }
                {active === 3 &&
                    <>
                        <Bonuses lang={lang}/>
                        <img alt={''} src={background2} className={classes.background2}/>
                        <Balance lang={lang} value={balance}/>
                        <Profit lang={lang} setBalance={setBalance} balance={balance}/>
                    </>
                }
                {active === 4 &&
                    <Roadmap lang={lang} setActive={setActive} data={data}/>
                }
                <Menu lang={lang} active={active} setActive={setActive}/>
                <Politics lang={lang}/>
            </div>
            <Withdraw lang={lang}
                      data={data}
                      openWithdraw={openWithdraw}
                      setOpenWithdraw={setOpenWithdraw}
                      cryptoAddress={cryptoAddress}
                      setTimer={setTimer}/>
            <Notificate text={texts[lang].notificate.text2} open={notification}/>
        </>
    );
};

export default Main;