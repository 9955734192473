import React, {useEffect, useState} from 'react';
import classes from "./timer.module.css";
import {texts} from "../../../../translate";

const Timer = ({lang, time, bonus}) => {
    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)

    function setTimer(){
        const daysT = Math.trunc((time - Date.now()) / (1000 * 60 * 60 * 24))
        setDays(daysT)
        const hoursT = Math.trunc((time - Date.now() - daysT * 1000 * 60 * 60 * 24) / (1000 * 60 * 60))
        setHours(hoursT)
        const minutesT = Math.trunc((time - Date.now() - daysT * 1000 * 60 * 60 * 24 - hoursT * 1000 * 60 * 60) / (1000 * 60))
        setMinutes(minutesT)
    }

    useEffect(()=>{
        setTimer()
        setInterval(setTimer,10000)
    },[time])

    const TimerItems = ({value})=>{
        return(
            <div>
                <div className={classes.block1}></div>
                <div className={classes.block2}></div>
                <div className={classes.block3}></div>
                <div className={classes.value}>{value}</div>
            </div>
        )
    }

    return (
        <div className={classes._}>
            <div className={classes.block}>
                <TimerItems value={days}/>
                <div style={{opacity:bonus?1:.5}}>{texts[lang].bonuses.day}</div>
            </div>
            <div className={classes.mt}>:</div>
            <div className={classes.block}>
                <TimerItems value={hours}/>
                <div style={{opacity:bonus?1:.5}}>{texts[lang].bonuses.hour}</div>
            </div>
            {bonus && <div className={classes.mt}>:</div>}
            {bonus && <div className={classes.block}>
                <TimerItems value={minutes}/>
                <div style={{opacity:bonus?1:.5}}>{texts[lang].bonuses.minute}</div>
            </div>}
        </div>
    );
};

export default Timer;