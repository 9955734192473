import React, {useEffect, useState} from 'react';
import classes from "./withdraw.module.css";
import {texts} from "../../../../translate";
import Button from "../button/button";
import {CSSTransition} from "react-transition-group";
import Notificate from "../notificate/notificate";
import {withdrawalRequest} from "../../../../utils/api";

const Withdraw = ({data, lang, openWithdraw, setOpenWithdraw, cryptoAddress, setTimer}) => {
    const [checked, setChecked] = useState(false)
    const [notification, setNotification] = useState(0);
    const [notification2, setNotification2] = useState(0);
    const [value, setValue] = useState(data?.user.balance * data?.withdrawal_setting.exchange_rate)

    useEffect(()=>{
        setValue(data?.user.balance * data?.withdrawal_setting.exchange_rate)
    },[data])

    useEffect(()=>{
        if(value <= data?.user.balance * data?.withdrawal_setting.exchange_rate && value >= data?.withdrawal_setting.min_amount){
            setChecked(true)
        }else{
            setChecked(false)
        }
    },[value])

    async function startWithdraw(){
        if(cryptoAddress === '' || !cryptoAddress){
            setNotification(Date.now());
        }else{
            if(checked){
                const res = await withdrawalRequest(value / data?.withdrawal_setting.exchange_rate)
                console.log(res)
                if(res.status === 'REQUEST'){
                    setTimer(Date.now())
                    setOpenWithdraw(false)
                }
            }else{
                if(value < data?.withdrawal_setting.min_amount){
                    setNotification2(Date.now());
                }
            }
        }
    }

    return (
        <>
            <CSSTransition in={openWithdraw}
                           timeout={500}
                           classNames="openBonusFon"
                           unmountOnExit
            >
                <div className={classes.fon} onClick={()=>setOpenWithdraw(false)}></div>
            </CSSTransition>
            <CSSTransition in={openWithdraw}
                           timeout={500}
                           classNames="openBonusMenu"
                           unmountOnExit
            >
                <div className={classes.menu}>
                    <div className={classes.closeBlock}>
                        <svg className={classes.close} onClick={()=>setOpenWithdraw(false)} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M35 20C35 21.9698 34.612 23.9204 33.8582 25.7403C33.1044 27.5601 31.9995 29.2137 30.6066 30.6066C29.2137 31.9995 27.5601 33.1044 25.7403 33.8582C23.9204 34.612 21.9698 35 20 35C18.0302 35 16.0796 34.612 14.2597 33.8582C12.4399 33.1044 10.7863 31.9995 9.3934 30.6066C8.00052 29.2137 6.89563 27.5601 6.14181 25.7403C5.38799 23.9204 5 21.9698 5 20C5 16.0218 6.58035 12.2064 9.3934 9.3934C12.2064 6.58035 16.0218 5 20 5C23.9782 5 27.7936 6.58035 30.6066 9.3934C33.4196 12.2064 35 16.0218 35 20ZM12.155 27.845C11.8425 27.5325 11.667 27.1086 11.667 26.6667C11.667 26.2247 11.8425 25.8009 12.155 25.4883L17.6433 20L12.155 14.5117C11.8514 14.1973 11.6834 13.7763 11.6872 13.3393C11.691 12.9023 11.8663 12.4843 12.1753 12.1753C12.4843 11.8663 12.9023 11.691 13.3393 11.6872C13.7763 11.6834 14.1973 11.8514 14.5117 12.155L20 17.6433L25.4883 12.155C25.6421 11.9958 25.826 11.8688 26.0293 11.7815C26.2327 11.6941 26.4514 11.6482 26.6727 11.6462C26.894 11.6443 27.1134 11.6865 27.3183 11.7703C27.5231 11.8541 27.7092 11.9779 27.8657 12.1343C28.0221 12.2908 28.1459 12.4769 28.2297 12.6817C28.3135 12.8866 28.3557 13.106 28.3538 13.3273C28.3518 13.5486 28.3058 13.7673 28.2185 13.9707C28.1312 14.174 28.0042 14.3579 27.845 14.5117L22.3567 20L27.845 25.4883C28.1486 25.8027 28.3166 26.2237 28.3128 26.6607C28.309 27.0977 28.1337 27.5157 27.8247 27.8247C27.5157 28.1337 27.0977 28.309 26.6607 28.3128C26.2237 28.3166 25.8027 28.1486 25.4883 27.845L20 22.3567L14.5117 27.845C14.1991 28.1575 13.7753 28.333 13.3333 28.333C12.8914 28.333 12.4675 28.1575 12.155 27.845Z" fill="#4C4F55"/>
                        </svg>
                    </div>
                    <div className={classes.menuText1}>{texts[lang].withdraw.text1}</div>
                    <div className={classes.block}>
                        <div className={classes.blockText}>{data?.user.balance} * {data?.withdrawal_setting.exchange_rate} = </div>
                        <input className={classes.input}
                               value={value}
                               type={'number'}
                               pattern="\\d*"
                               inputmode="decimal"
                               onChange={(e)=> {
                                   if(e.target.value.length === 0){
                                       setValue('')
                                   }else{
                                       if (e.target.value <= data?.user.balance * data?.withdrawal_setting.exchange_rate) {
                                           let last = e.target.value[e.target.value.length - 1]
                                           const alf = '1234567890,.'
                                           if(e.target.value === '.' || e.target.value === ','){
                                               setValue('0,')
                                           }else{
                                               if (alf.indexOf(last) !== -1) {
                                                   if(((last === '.' || last === ',') &&
                                                       (e.target.value.slice(0,-1).indexOf('.') !== -1 || e.target.value.slice(0,-1).indexOf(',') !== -1)) ||
                                                       ((e.target.value.indexOf('.') !== -1 || e.target.value.indexOf(',') !== -1) &&
                                                           (e.target.value.length - Math.max(e.target.value.indexOf('.'),e.target.value.indexOf(',')))) > 3
                                                   ){

                                                   }else{
                                                       setValue(e.target.value)
                                                   }
                                               }
                                           }
                                       }else{
                                           setValue(data?.user.balance * data?.withdrawal_setting.exchange_rate)
                                       }
                                   }

                               }}/>
                        <div className={classes.blockText}>USDT/BSC</div>
                    </div>
                    <div className={classes.text2}>{texts[lang].withdraw.text2} {data?.withdrawal_setting.min_amount} USDT</div>
                    <div className={classes.menuButtons}>
                        <Button active={checked} click={startWithdraw}>
                            {texts[lang].button.text2}
                        </Button>
                    </div>
                </div>
            </CSSTransition>
            <Notificate text={texts[lang].notificate.text3} open={notification}/>
            <Notificate text={texts[lang].notificate.text4} open={notification2}/>
        </>
    );
};

export default Withdraw;