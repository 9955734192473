import React from 'react';
import classes from "./balance.module.css";
import {texts} from "../../../../translate";

const Balance = ({lang, value}) => {
    return (
        <div className={classes._}>
            <div className={classes.text1}>{texts[lang].balance.text1}</div>
            <div className={classes.text2}>{value}</div>
            <div className={classes.text1}>{texts[lang].balance.text2}</div>
        </div>
    );
};

export default Balance;